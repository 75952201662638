<template lang="">
  <div>
    <LoaderLinear />
    <v-card elevation="0" class="pa-5 ma-2">
      <form @submit.prevent="sendForm" id="formProject">
        <v-row>
           <v-col cols="12" md="12">
            <LoadImageSingle ref="imageComponent" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              v-model="$v.name.$model"
              :error-messages="nameErrors"
              @blur="$v.name.$touch()"
              label="Nombre del proyecto (*)"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-textarea
              outlined
              v-model="$v.description.$model"
              label="Descripción (*)"
              :error-messages="descriptionErrors"
              @blur="$v.description.$touch()"
            >
            </v-textarea>
          </v-col>
          <v-col cols="12">
            <v-textarea outlined v-model="observation" label="Observación">
            </v-textarea>
          </v-col>
         
        </v-row>

        <v-divider></v-divider>

        <v-row class="mt-3">
          <v-col cols="12" md="6">
            <span class="fvl-title-form">
              Ubicación
            </span>
          </v-col>
        </v-row>

        <v-row class="mt-3">
          <v-col cols="12" md="6">
            <v-text-field
              v-model="$v.latitude.$model"
              :error-messages="latitudeErrors"
              @blur="$v.latitude.$touch()"
              outlined
              label="Latitud (*)"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="$v.longitude.$model"
              :error-messages="longitudeErrors"
              @blur="$v.longitude.$touch()"
              outlined
              label="Longitud (*)"
            >
            </v-text-field>
          </v-col>
        </v-row>
        
        <v-row>
          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="country"
              :items="getCountries"
              label="País"
              outlined
            
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              v-model="province"
              :items="getProvincesByCountryId(country)"
              label="Provincia"
              outlined
              allow-overflow="true"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              outlined
              v-model="$v.locality.$model"
              :error-messages="localityErrors"
              @blur="$v.locality.$touch()"
              :items="getLocalitiesByProvinceId(country, province)"
              label="Localidad (*)"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field outlined v-model="street" label="Calle">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field outlined v-model="number_address" label="Número">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field outlined v-model="flat" label="Piso"> </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field outlined v-model="department" label="Departamento">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-autocomplete
              outlined
              v-model="$v.typeSelected.$model"
              :error-messages="typeSelectedErrors"
              @blur="$v.typeSelected.$touch()"
              :items="getProjectTypes"
              multiple
              label="Tipo de Proyecto (*)"
            ></v-autocomplete>
            <!-- <SelectProjectType /> -->
          </v-col>
          <v-col cols="12" md="6">
            <v-checkbox v-model="isPublic" label="Pública"></v-checkbox>
          </v-col>
        </v-row>
        <v-row class="justify-end">
          <v-col>
            <v-btn
              v-if="customSteps.length === 0 && !addPerson"
              class="mx-2 float-right fvl-btn green-primary"
              icon
              title="Agregar Etapa"
              @click="addStep"
            >
              <v-icon>playlist_add</v-icon>
            </v-btn>

            <v-btn
              v-if="customSteps.length === 0 && !addPerson"
              class="mx-2 float-right fvl-btn green-primary"
              icon
              title="Agregar Etapa"
              @click="setAddPerson"
            >
              <v-icon>person_add</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="addPerson">
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
             <span class="fvl-title-form">
              Nueva Persona
            </span>
          </v-col>
        </v-row>
        <v-row v-if="addPerson">
          <v-col cols="12" md="5">
            <SelectProjectRol />
          </v-col>
          <v-col cols="12" md="5">
            <SelectUsers />
          </v-col>
          <v-col cols="12" md="2" class="align-self-center">
            <v-btn
              depressed
              class="float-right mt-6"
              icon
              small
              :disabled="disabledPerson()"
              @click="addPersonProject()"
            >
              <v-icon color="primary">done</v-icon>
            </v-btn>
            <v-btn
              depressed
              class="float-right mt-6"
              icon
              small
              @click="cancelPerson()"
            >
              <v-icon color="primary">close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="customSteps.length > 0">
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
             <span class="fvl-title-form">
              Nueva Etapa
            </span>
          </v-col>
        </v-row>
        <v-row v-for="(step, index) in customSteps" :key="index">
          <v-text-field
            label="id"
            v-model="step.id"
            class="input-hidden"
            outlined
          >
          </v-text-field>
          <v-col cols="12" md="3">
            <v-text-field label="Nombre (*)" v-model="step.name" outlined>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              label="Descripción (*)"
              v-model="step.description"
              outlined
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-checkbox v-model="step.is_public" label="Pública"></v-checkbox>
          </v-col>
          <v-col cols="12" md="2" class="align-self-center">
            <v-btn
              depressed
              class="float-right mt-6"
              icon
              small
              :disabled="disabledStep(index)"
              @click="addItem(index)"
            >
              <v-icon color="primary">done</v-icon>
            </v-btn>
            <v-btn
              depressed
              class="float-right mt-6"
              icon
              small
              @click="removeItem(index)"
            >
              <v-icon color="primary">close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row v-if="noSelectedStepsByDefault.length > 0">
          <v-col cols="12" md="6">
             <span class="fvl-title-form">
              Etapas Predeterminadas
            </span>
          </v-col>
        </v-row>
        <v-row v-if="noSelectedStepsByDefault.length > 0" elevation="3">
          <v-list-item
            v-for="(step, index) in noSelectedStepsByDefault"
            :key="index"
          >
            <v-list-item-content :key="step.id">
              <v-list-item-title v-text="step.name"></v-list-item-title>
              <v-list-item-subtitle v-if="step.isNew" v-text="`Nuevo`">
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn @click="addItemDefault(step)" icon>
                <v-icon>add_circle</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-row>
        <v-row v-if="personsProject.length > 0">
          <v-col cols="12" md="6">
           <span class="fvl-title-form">
              Personas Asociadas
            </span>
          </v-col>
        </v-row>
        <v-row v-if="personsProject.length > 0" elevation="3">
          <v-list-item v-for="(person, index) in personsProject" :key="index">
            <v-list-item-content :key="person.person_id">
              <v-list-item-title>
                {{ getUserById(person.id).get_person.name }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="getRolById(person.project_rol_id)"
                >Rol:
                {{ getRolById(person.project_rol_id).description }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn @click="removePerson(index, person)" icon
                ><v-icon color="primary">delete</v-icon></v-btn
              >
            </v-list-item-action>
          </v-list-item>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
           <span class="fvl-title-form">
              Etapas Seleccionadas
            </span>
          </v-col>
        </v-row>
        <v-row>
          
          <v-container fluid justify-center>
            <v-flex class="elevation-1 pa-3 ma-2">
              <v-list v-if="selectedSteps.length > 0">
                <v-list-item-group>
                  <draggable v-model="selectedSteps" @change="changeDrag()">
                    <v-list-item v-for="(step, i) in selectedSteps">
                      <v-list-item-avatar>
                        <v-avatar color="grey" size="56">
                          <span>
                            {{ i + 1 }}
                          </span>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content :key="step.step_id">
                        <v-list-item-title
                          v-text="step.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-if="step.isNew"
                          v-text="`Nuevo`"
                        >
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn @click="remove(i)" icon
                          ><v-icon color="primary">delete</v-icon></v-btn
                        >
                      </v-list-item-action>
                    </v-list-item>
                  </draggable>
                </v-list-item-group>
              </v-list>
              <v-row v-else>
                <v-col cols="12" md="6">
                  <v-alert color="blue" outlined type="info"
                    >No hay etapas seleccionadas para esta obra</v-alert
                  >
                </v-col>
              </v-row>
            </v-flex>
          </v-container>
        </v-row>
        <v-row>
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>
      
        <v-row>
          <v-col>
            <Button
              :disabled="$v.$invalid"
              classes="mr-4 float-right fvl-btn green-primary"
              type="submit"
              name="Guardar"
            />
            <router-link :to="goToBack">
              <Button
                :disabled="false"
                classes="mr-4 float-right fvl-btn green-military"
                type="button"
                name="Volver"
              />
            </router-link>
          </v-col>
        </v-row>
      </form>
      <Overlay />
    </v-card>
    <SnackBar />
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { mapState, mapGetters } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import draggable from "vuedraggable";
import {
  LoaderLinear,
  SnackBar,
  Overlay,
  Button,
  LoadImageSingle,
} from "../_commons";
import { beginRequest, endRequest, links } from "../../helpers";
import SelectProjectType from "../projectTypes/SelectProjectType.vue";
import SelectProjectRol from "../projectRol/SelectProjectRol.vue";
import SelectUsers from "../users/SelectUsers.vue";

export default {
  name: "FormProject",
  components: {
    LoaderLinear,
    SnackBar,
    Overlay,
    Button,
    SelectProjectType,
    SelectProjectRol,
    SelectUsers,
    LoadImageSingle,
    draggable,
  },
  props: ["actionForm"],
  mixins: [validationMixin],
  validations: {
    name: { required, minLength: minLength(3) },
    description: { required, minLength: minLength(6) },
    latitude: { required, minLength: minLength(2) },
    longitude: { required, minLength: minLength(2) },
    locality: { required },
    typeSelected: { required },
  },
  data() {
    return {
      before: null,
      editing: true,
      addPerson: false,
      goToBack: links.project.list,
      idproject: 0,
      name: "",
      description: "",
      observation: "",
      latitude: "",
      longitude: "",
      id_address: null,
      street: "",
      number_address: null,
      flat: "",
      department: "",
      country: null,
      province: null,
      locality: null,
      typeSelected: "",
      isPublic: false,
      document: "",
      selectedSteps: [],
      noSelectedStepsByDefault: [],
      customSteps: [],
      personsProject: [],
    };
  },
  methods: {
    sendForm() {
      let formData = new FormData();
      const person_project = this.personsProject;
      const is_public_project = this.isPublic ? 1 : 0;
      formData.append("id", this.idproject);
      formData.append("name_project", this.name);
      formData.append("observation", this.observation);
      formData.append("description", this.description);
      formData.append("id_address", this.id_address);
      formData.append("latitude", this.latitude);
      formData.append("longitude", this.longitude);
      formData.append("street", this.street);
      formData.append("number_address", this.number_address);
      formData.append("flat", this.flat);
      formData.append("department", this.department);
      formData.append("locality", this.locality);
      formData.append("is_public", is_public_project);
      formData.append("id_person_authenticate", 1);

      for (let i = 0; i < this.typeSelected.length; i++) {
        formData.append(`project_type_id[${i}]`, this.typeSelected[i]);
      }
      for (let i = 0; i < person_project.length; i++) {
        const person = person_project[i];
        formData.append(`person_id[${i}]`, person.id);
        formData.append(`project_rol_id[${i}]`, person.project_rol_id);
      }

      for (let i = 0; i < this.selectedSteps.length; i++) {
        // if (this.selectedSteps[i].selected) {
        formData.append(`id_project_step[${i}]`, this.selectedSteps[i].id);
        formData.append(`step_id[${i}]`, this.selectedSteps[i].id_step);
        formData.append(`step_name[${i}]`, this.selectedSteps[i].name);
        formData.append(
          `step_description[${i}]`,
          this.selectedSteps[i].description
        );
        const is_public_step = this.selectedSteps[i].is_public ? 1 : 0;
        formData.append(`step_is_public[${i}]`, is_public_step);
        formData.append(`step_order[${i}]`, this.selectedSteps[i].order);
        // }
      }

      if (this.documentsStore.documentsSelected.length === 0) {
        formData.append(`image`, null);
      } else {
        const doc = this.documentsStore.documentsSelected[0];
        formData.append(`image`, doc.doc_file);
      }
      // for (var key of formData?.entries()) {
      //   console.log(key[0] + ", " + key[1]);
      // }
      this.$emit("sendForm", formData);
    },
    async resetFormMethod() {
      (this.name = ""),
        (this.description = ""),
        (this.observation = ""),
        (this.latitude = ""),
        (this.longitude = ""),
        (this.isPublic = null);
      this.customSteps = [];
      this.personsProject = [];
      this.selectedSteps = await this.getAllStepsSelectedByDefault;
      await this.$store.dispatch("removeDocumentsSelectedAction");
      await this.$store.dispatch("removeUsersSelectedAction");
      this.noSelectedStepsByDefault = [];
      this.$v.$reset();
    },
    addStep() {
      // DEBO AGREGARLOS AL STORE
      this.customSteps.push({
        id: "",
        name: "",
        description: "",
        is_public: "",
      });
    },
    removeItem(index) {
      // QUITARLO DEL STORE
      this.customSteps.splice(index, 1);
    },
    changeDrag() {
      this.selectedSteps.map((step, index) => {
        step.order = index + 1;
        return step;
      });
    },
    addItem(index) {
      const stepAdded = this.customSteps[index];
      const step = {
        id: 0,
        name: stepAdded.name,
        description: stepAdded.description,
        order: this.selectedSteps.length + 1,
        is_public: stepAdded.is_public,
        selected: true,
        isNew: true,
      };
      this.selectedSteps = [...this.selectedSteps, step];
      this.customSteps.splice(index, 1);
    },
    async addItemDefault(step) {
      const stepAdded = {
        id: step.id,
        id_step: step.id,
        name: step.name,
        description: step.description,
        order: this.selectedSteps.length + 1,
        is_public: step.is_public,
        selected: true,
        isNew: false,
      };
      this.selectedSteps = [...this.selectedSteps, stepAdded];
      this.noSelectedStepsByDefault = await this.$store.dispatch(
        "getStepsSelectedByDefaultNoSelected",
        this.selectedSteps
      );
    },
    action(e) {
      if (e === "edit") this.before = Object.assign([], this.selectedSteps);
      if (e === "undo") this.selectedSteps = this.before;
      this.editing = !this.editing;
    },
    async remove(i) {
      this.$delete(this.selectedSteps, i);
      this.noSelectedStepsByDefault = await this.$store.dispatch(
        "getStepsSelectedByDefaultNoSelected",
        this.selectedSteps
      );
    },
    disabledStep(index) {
      return this.customSteps[index].name !== "" &&
        this.customSteps[index].description !== ""
        ? false
        : true;
    },
    setAddPerson() {
      this.addPerson = !this.addPerson;
    },
    disabledPerson() {
      return this.projects.rolSelected !== "" &&
        this.users.userSelected !== "" &&
        this.projects.rolSelected !== null &&
        this.users.userSelected !== null
        ? false
        : true;
    },
    async addPersonProject() {
      const newPerson = {
        id: this.users.userSelected,
        project_rol_id: this.projects.rolSelected,
      };
      this.personsProject = [...this.personsProject, newPerson];
      await this.$store.dispatch(
        "setUsersSelectedAction",
        this.users.userSelected
      );
      await this.$store.dispatch("removeUserSelectedAction");
      await this.$store.dispatch("removeProjectRolSelected");
      this.setAddPerson();
    },
    async cancelPerson() {
      await this.$store.dispatch("removeUserSelectedAction");
      await this.$store.dispatch("removeProjectRolSelected");
      this.setAddPerson();
    },
    async removePerson(index, person) {
      this.personsProject.splice(index, 1);
      await this.$store.dispatch("removeUsersSelectedByIndexAction", person);
    },
  },
  computed: {
    ...mapState({
      projects: (state) => state.projects,
      documentsStore: (state) => state.documents,
      steps: (state) => state.steps,
      users: (state) => state.users,
      localities: (state) => state.localities,
    }),
    ...mapGetters([
      "getAllStepsSelectedByDefault",
      "getRolById",
      "getUserById",
      "getCountries",
      "getProvincesByCountryId",
      "getLocalitiesByProvinceId",
      "getProjectTypes",
    ]),
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      !this.$v.description.minLength &&
        errors.push("La longitud minima es de 6 caracteres");
      !this.$v.description.required &&
        errors.push("La descripción es requerida");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.minLength &&
        errors.push("La longitud minima es de 3 caracteres");
      !this.$v.name.required && errors.push("El nombre es requerido");
      return errors;
    },
    latitudeErrors() {
      const errors = [];
      if (!this.$v.latitude.$dirty) return errors;
      !this.$v.latitude.minLength &&
        errors.push("La longitud minima es de 2 caracteres");
      !this.$v.latitude.required && errors.push("Latitud es requerido");
      return errors;
    },
    longitudeErrors() {
      const errors = [];
      if (!this.$v.longitude.$dirty) return errors;
      !this.$v.longitude.minLength &&
        errors.push("La longitud minima es de 2 caracteres");
      !this.$v.longitude.required && errors.push("Longitud es requerido");
      return errors;
    },
    typeSelectedErrors() {
      const errors = [];
      if (!this.$v.typeSelected.$dirty) return errors;
      !this.$v.typeSelected.required &&
        errors.push("El tipo de proyecto es requerido");
      return errors;
    },
    localityErrors() {
      const errors = [];
      if (!this.$v.locality.$dirty) return errors;
      !this.$v.locality.required && errors.push("La localidad es requerida");
      return errors;
    },
    getStepsByDefaultNoSelected() {
      return "Datos";
    },
  },
  async created() {
    await beginRequest();
    await this.$store.dispatch("removeDocumentsSelectedAction");
    await this.$store.dispatch("getStepsByDefaultAction");
    await this.$store.dispatch("setProjectRolAction");
    await this.$store.dispatch("setLocalitiesActions");
    await this.$store.dispatch("getAllUsersAction");
    const { status, snack } = await this.$store.dispatch(
      "setProjectTypesAction"
    );
    if (this.actionForm === "Edit") {
      await beginRequest();
      const { snack, status } = await this.$store.dispatch(
        "getProjectById",
        this.$route.params.idproject
      );
      if (this.projects.projectSelected) {
        this.idproject = this.projects.projectSelected.project.id;
        this.name = this.projects.projectSelected.project.name;
        this.description = this.projects.projectSelected.project.description;
        this.observation = this.projects.projectSelected.project.observation;
        this.id_address = this.projects.projectSelected.address[0].id_address;
        this.latitude = this.projects.projectSelected.address[0].latitude;
        this.longitude = this.projects.projectSelected.address[0].longitude;
        this.country = this.projects.projectSelected.address[0].id_country;
        this.province = this.projects.projectSelected.address[0].id_province;
        this.locality = this.projects.projectSelected.address[0].id_locality;
        this.street = this.projects.projectSelected.address[0].street;
        this.number_address = this.projects.projectSelected.address[0].number;
        this.flat = this.projects.projectSelected.address[0].flat;
        this.department = this.projects.projectSelected.address[0].department;
        this.typeSelected = this.projects.projectSelected.project_types.map(
          (el) => el.id
        );
        this.isPublic =
          this.projects.projectSelected.project.is_public === 1 ? true : false;
        const doc = this.projects.projectSelected.project.image;
        if (doc !== "") {
          const document = {};
          document.doc_file = doc;
          document.link = `${process.env.VUE_APP_SERVER}${doc}`;
          await this.$store.dispatch("addDocumentsSelectedAction", document);
        }

        this.selectedSteps = this.projects.projectSelected.steps.map(
          (el) => el.step
        );
        this.personsProject = this.projects.projectSelected.persons;
        this.projects.projectSelected.persons.forEach(async (item) => {
          await this.$store.dispatch("setUsersSelectedAction", item.id);
        });
        this.noSelectedStepsByDefault = await this.$store.dispatch(
          "getStepsSelectedByDefaultNoSelected",
          this.selectedSteps
        );
      }
      await endRequest(snack, status);
      return;
    } else {
      this.selectedSteps = await this.getAllStepsSelectedByDefault;
      this.noSelectedStepsByDefault = await this.$store.dispatch(
        "getStepsSelectedByDefaultNoSelected",
        this.selectedSteps
      );
    }
    await endRequest(snack, status);
    this.documents = this.$refs.imageComponent?.documents;
  },
};
</script>
<style scoped>
.input-hidden {
  display: none;
}
.draggable-flex {
  display: inherit;
  flex-direction: inherit;
}
</style>
