<template lang="">
  <div justify="space-around" class="content-strepper">
    
      <div>
         <div class="divider-stepper"> <div class="divider-stepper-live"></div></div>
        <v-chip-group
          mandatory
          center-active
          active-class="stepper-active"
          class="stepper"
        >
          <div v-for="(step, index) in project.steps" class="div-stepper-item">
            <div class="privacity-step">
              {{ step.step.is_public === 0 ? "Privada" : "Pública" }}
            </div>
            <div class="title-step">
              {{ step.step.name }}
            </div>
            <v-chip class="stepper-item" @click="setStepSelected(step.step.id)">
            </v-chip>
          </div>
        </v-chip-group>
       
      </div>

  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ProjectStrepper",
  data() {
    return {};
  },
  methods: {
    setStepSelected(id) {
      this.$store.dispatch("setStepProjectelectedAction", id);
    },
  },
  computed: {
    ...mapState({
      project: (state) => state.projects.projectSelected,
      user: (state) => state.user,
    }),
  },
};
</script>
