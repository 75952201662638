<template lang="">
  <div>
    <v-autocomplete
      :value="this.projects.rolSelected"
      :items="getProjectRol"
      label="Rol (*)"
      @change="setRolProject"
      outlined
    ></v-autocomplete>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
export default {
  methods: {
    setRolProject(e) {
      this.$store.dispatch("setProjectRolSelectedAction", e);
    },
  },
  computed: {
    ...mapState({
      projects: (state) => state.projects,
    }),
    ...mapGetters(["getProjectRol"]),
  },
};
</script>
