<template lang="">
  <div>
    <!-- Header Project Section -->
    <div class="container-header-carousel">
      <!-- <span class="home-title-section">Mis Proyectos</span> -->
      <div class="container-search-carousel">
      <v-text-field
        v-model="nameProject"
        label="Buscador de proyectos"
        class="searcher-input-fvl elevation-1"
        append-icon="search"
        dense
        filled
        hide-details
      ></v-text-field>
      </div>
    </div>

    <!-- Header Project Section -->
    <v-sheet
      class="mx-auto"
      elevation="0"
      v-if="getProjectsByNameAction.length > 0"
      >
      <v-slide-group class="container-carousel" show-arrows mobile-breakpoint="100px" center-active>
        <v-slide-item
          class="carousel"
          v-for="(project, index) in getProjectsByNameAction"
          :key="project.project.id"
          v-slot="{ active, toggle }"
        >
          <v-card
            class="project-carousel-card"
            elevation="1"
            @click="viewDetail(project)"
            :color="active ? 'primary' : 'grey lighten-1'"
            
          >
            <p class="carousel-text">{{ project.project.name }}</p>
            <div class="project-carousel-img">
            <img
              :src="getUrlImg(project.project.image)"
              aspect-ratio="1"
              style="opacity: 0.7!important;"
            />
            </div>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>    
    <div class="container-none-projects" v-else>
      No se encontraron proyectos asignados.
    </div>
  </div>
</template>
<script>
import carousel from "vue-owl-carousel";
import { links } from "../../helpers";
import { mapGetters } from "vuex";
import router from "../../router";

export default {
  name: "CarouselImage",
  components: { carousel },
  data() {
    return {
      nameProject: "",
    };
  },
  methods: {
    getUrlImg(image) {
      const url = image
        ? `${process.env.VUE_APP_SERVER}${image}`
        : "../../assets/img/item1.png";
      return url;
    },
    async viewDetail(item) {
      await this.$store.dispatch("setProjectSelectedAction", null);
      router.push(`${links.project.detail}/${item.project.id}`);
    },
  },
  computed: {
    ...mapGetters(["getProjectsByName"]),
    getProjectsByNameAction() {
      return this.getProjectsByName(this.nameProject);
    },
  },
};
</script>
<style lang=""></style>
