<template lang="">
  <div>
    <v-autocomplete
      :value="this.users.userSelectedData"
      :items="getActiveUserToSelectRestricted"
      label="Usuario (*)"
      @change="setUserSelected"
      outlined
    ></v-autocomplete>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "SelectUsers",
  methods: {
    setUserSelected(e) {
      this.$store.dispatch("setUserSelectedAction", e);
    },
  },
  computed: {
    ...mapState({
      users: (state) => state.users.users,
    }),
    ...mapGetters(["getActiveUserToSelectRestricted"]),
  },
};
</script>
