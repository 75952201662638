<template lang="">
  <v-container
    v-if="
      user.permissions.indexOf(permissionsAll.project_update) >= 0 ||
        user.person.types_person.id === 3
    "
  >
    <BreadCrumbs :items="itemsBreadCrumbs" />
    <FormProject
      :actionForm="actionForm"
      @sendForm="sendForm"
      ref="formComponent"
    />
  </v-container>
</template>
<script>
import { beginRequest, endRequest, itemsBreadCrumbs } from "../../helpers";
import { FormProject } from "../../components/project";
import { BreadCrumbs } from "../../components/_commons";
import permissions from "../../helpers/permissions";
import { mapState } from "vuex";
export default {
  name: "ProjectEdit",
  components: {
    BreadCrumbs,
    FormProject,
  },
  data() {
    return {
      itemsBreadCrumbs: itemsBreadCrumbs.projectEdit,
      actionForm: "Edit",
      permissionsAll: permissions,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    async sendForm(formData) {
      await beginRequest();
      const payload = {
        id: this.$route.params.idproject,
        data: formData,
      };
      const { snack, status } = await this.$store.dispatch(
        "updateProject",
        payload
      );
      await endRequest(snack, status);
    },
  },
};
</script>
