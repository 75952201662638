<template>
  <v-container id="body-container-card-fvl">
    <BreadCrumbs :items="itemsBreadCrumbs" />
    <LoaderLinear />
    <div id="fvl-container-card-table">
    <v-card elevation="0" class="fvl-card-searcher-table">
      <div class="d-flex justify-center">
        <v-text-field
          v-model="search"
          class="mb-6 searcher-input-fvl elevation-1"
          append-icon="search"
          label="Buscador de proyectos"
          dense
          filled
          hide-details
        ></v-text-field>
      </div>
      <v-data-table
        :headers="dataTableheaders"
        :items="projects.projects"
        :search="search"
      >
        <template v-slot:[`item.project_types`]="{ item }">
          <div v-for="(type, index) in item.project_types" :key="index">
            {{ type.name }}
          </div>
        </template>
        <template v-slot:[`item.project.is_public`]="{ item }">
          {{ item.project.is_public == 1 ? "Público" : "Privado" }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
            v-if="
              user.permissions.indexOf(permissionsAll.project_update) >= 0 ||
                user.person.types_person.id === 3
            "
          >
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
            v-if="
              user.permissions.indexOf(permissionsAll.project_delete) >= 0 ||
                user.person.types_person.id === 3
            "
          >
            delete
          </v-icon>
        </template>
      </v-data-table>

    </v-card>
     </div>
    <SnackBar />
    <ButtonAdd
      :link="linkAdd"
      v-if="
        user.permissions.indexOf(permissionsAll.project_create) >= 0 ||
          user.person.types_person.id === 3
      "
    />
    <DialogConfirm @action="deleteProject" />
    <Overlay />
   
  </v-container>
</template>

<script>
import router from "../../router";
import {
  links,
  itemsBreadCrumbs,
  beginRequest,
  endRequest,
} from "../../helpers";
import {
  LoaderLinear,
  SnackBar,
  Overlay,
  DialogConfirm,
  BreadCrumbs,
  ButtonAdd,
} from "../_commons";
import permissions from "../../helpers/permissions";
import { mapState } from "vuex";
export default {
  name: "CardSteps",
  components: {
    BreadCrumbs,
    ButtonAdd,
    LoaderLinear,
    DialogConfirm,
    SnackBar,
    Overlay,
  },
  data() {
    return {
      linkAdd: links.project.create,
      itemsBreadCrumbs: itemsBreadCrumbs.projectList,
      search: "",
      dataTableheaders: [
        { text: "id", value: "project.id",  class:"file-name-table" },
        {
          text: "Nombre",
          align: "start",
          value: "project.name",
          class:"file-name-table"
        },
        {
          text: "Descripción",
          align: "start",
          value: "project.description",
          width: 400,
          class:"file-name-table"
        },
        { text: "Alcance", value: "project.is_public", class:"file-name-table" },
        { text: "Tipo", value: "project_types", class:"file-name-table" },
        { text: "Acciones", value: "actions", class:"file-name-table", sortable: false },
      ],
      permissionsAll: permissions,
    };
  },
  methods: {
    async editItem(item) {
      await this.$store.dispatch("setProjectSelectedAction", null);
      router.push(`${links.project.update}/${item.project.id}`);
    },
    async deleteItem(item) {
      const msg = `¿Esta seguro que desea eliminar la obra "${item.project.name}"?`;
      await this.$store.dispatch("setIdItemDialogAction", item.project.id);
      await this.$store.dispatch("setMsgDialogConfirmAction", msg);
      await this.$store.dispatch("setDialogShowAction");
    },
    async deleteProject() {
      await beginRequest();
      await this.$store.dispatch("setDialogShowAction");
      const { status, snack } = await this.$store.dispatch(
        "deleteProject",
        this.ui.idItemDialog
      );
      await endRequest(snack, status);
    },
  },
  computed: {
    ...mapState({
      projects: (state) => state.projects,
      ui: (state) => state.ui,
      user: (state) => state.user,
    }),
  },
  async created() {
    await beginRequest();
    const { status, snack } = await this.$store.dispatch("getProjects");
    await endRequest(snack, status);
  },
};
</script>

<style></style>
