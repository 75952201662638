<template lang="">
  <div>
    <v-autocomplete
      :value="this.projects.typeSelected"
      :items="getProjectTypes"
      multiple
      label="Tipo de Proyecto (*)"
      @change="setTypeProject"
      outlined
    ></v-autocomplete>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "SelectTypes",
  methods: {
    setTypeProject(e) {
      this.$store.dispatch("setProjectTypeSelectedAction", e);
    },
  },
  computed: {
    ...mapState({
      projects: (state) => state.projects,
    }),
    ...mapGetters(["getProjectTypes"]),
  },
};
</script>
